
$(document).on('turbolinks:load', function () { 

  if (location.search.includes("?folder_id")) {
      console.log("loaded")
      // Function to get the value of a specific query parameter from the URL
          function getQueryParameter(param) {
              const urlParams = new URLSearchParams(window.location.search);
              return urlParams.get(param);
          }

          // Example usage
          const folderId = getQueryParameter('folder_id');

          if (folderId) {
              // Open all parent folders of the specified folder
              const folderElement = document.querySelector(`[href*="folder_id=${folderId}"]`);
              
              if (folderElement) {
                  // Traverse up the DOM tree to open all parent folders
                  let parentElement = folderElement.closest('ul.collapse');
                  
                  while (parentElement) {
                      if (!parentElement.classList.contains('show')) {
                          new bootstrap.Collapse(parentElement, {
                          toggle: true
                          });
                      }
                      parentElement = parentElement.closest('li').closest('ul.collapse');
                  }
              }
          }
  }

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  if ($(".codemirror-editor").length > 0) {
    CodeMirror.defineMode("htmlmixedWithRuby", function(config) {
        return CodeMirror.multiplexingMode(
            CodeMirror.getMode(config, "htmlmixed"), // Primary mode
            {
                open: "<%", // ERB open tag
                close: "%>", // ERB close tag
                mode: CodeMirror.getMode(config, "ruby"), // Mode for content within ERB tags
                delimStyle: "delimit" // Optional CSS class to style delimiter specifically
            }
        );
    });

    $(".codemirror-editor").each(function() {
        CodeMirror.fromTextArea(this, {
            lineNumbers: true,
            lines: 20,
            styleActiveLine: true,
            matchBrackets: true,
            theme: "3024-night",
            mode: "htmlmixedWithRuby",
        });
    });
}
  
window.fireFeatureForm = function () {
  $(".feature-edit-form input").change(function () {
    $(this).closest(".feature-edit-form").find("input[type='submit']").click();
  } )
}
  

  // Debounce function implementation
  function debounce(func, wait) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
  
    // Debounced event handler
    var handleKeyUp = debounce(function() {
      $(".search-spinner").removeClass("d-none");
      console.log("searching")
      $(this).siblings("input[type='submit']").click();
    }, 500); // Adjust 250 to whatever delay (in milliseconds) you find suitable


  $(".search-form").keyup(handleKeyUp);


  $("#company-changer").change(function () {
    var url = $(this).val();
    window.location = url
  })

  window.formLoadingIndicator = function () {
    console.log("load indicator")
    $(".form-loading-indicator").submit(function () {
      toastr.info("Data is saving. This should only take a moment.", "", {progressBar: true, timeOut: 90000})
    })
  }


  

  window.formLoadingIndicator();

  window.fileList = function () {
    console.log("file list")
    $(document).on('change', '.file-upload', function () {
    
      
      var files = $(this)[0].files;
      var names = [];
      var fileNumber = 0;
      
      $.each(files, function (key, value) {
        names.push(value['name'].substring(0, 100));
        fileNumber += 1;
      });
      
      // Find the closest parent div containing this instance of .file-upload
      var parent = $(this).closest("div");
      var fileNames = parent.find(".file-names");
      
      $(fileNames).text(names.join(', '));
      $('#file-number').text(`${fileNumber} file(s) added`);
    });

    $('#flyer-file-upload').change(function () {
      console.log("changed")
      var files = $('#flyer-file-upload')[0].files;
      var names = [];
      $.each(files, function (key, value) {
        names.push('' + value['name'] + '</br>');
      });
      $('#flyer-file-names').html(names);
    });
    
  }

  window.fileList();
  
  window.triggerTooltip = function () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  };

  window.triggerTooltip();
});